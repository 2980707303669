<template>
  <div id="sx_header" class="header align-items-stretch">
    <div
      class="container-fluid w-100"
      :class="{
        'p-0': $isMobile(),
      }"
    >
      <div
        class="d-flex align-items-stretch justify-content-between flex-lg-grow-1 h-100"
        :class="{ 'w-100': !this.$store.getters._isSideBarActive }"
      >
        <div
          class="d-flex"
          :class="{ 'd-lg-none': this.$store.getters._isSideBarActive }"
        >
          <div
            class="d-flex align-items-center d-lg-none"
            v-if="this.$store.getters._isSideBarActive"
          >
            <div class="btn btn-icon btn-sm" id="sx_aside_mobile_toggle">
              <i class="bi bi-box-arrow-right not-white text-primary fs-28"></i>
            </div>
          </div>
          <div
            class="menu-item ps-0 p-1 me-2 text-center navbar-header d-flex justify-content-center align-items-center"
          >
            <router-link :to="this.$appHomeUrl"
              ><img
                alt="SetXRM Cloud Solutions"
                :src="$config.media.logos.light"
                class="h-45px logo"
                id="sx-app-header-logo"
                :class="{
                  'ms-1': $isMobile(),
                }"
            /></router-link>
          </div>
          <div class="menu-item">
            <div
              class="project-name-no-aside"
              id="project-name-no-aside"
              :title="this.$store.getters._companyName"
            >
              {{ this.$store.getters._companyName }}
            </div>
          </div>
        </div>
        <div class="d-flex" id="sx_header_menu_nav">
          <Menu></Menu>
        </div>
        <div class="d-flex flex-shrink-0 topbar">
          <Topbar></Topbar>
        </div>
      </div>
    </div>
  </div>
  <DelegationSidePanel v-if="this.$store.getters._isDeletegationActive" />
</template>

<script>
import { defineComponent } from "vue";
import Topbar from "@/layout/header/Topbar.vue";
import Menu from "@/layout/header/Menu.vue";
import DelegationSidePanel from "@/layout/header/partials/delegation/DelegationSidePanel.vue";

import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";

export default defineComponent({
  name: "Header",
  data() {
    return {};
  },
  props: {
    title: String,
  },
  methods: {
    headerLogoMouseOver() {
      if (this.$root.checkElementHasClass("body", "aside-none")) {
        document
          .getElementById("project-name-no-aside")
          .classList.add("hidden");
        document
          .getElementById("logo-text-no-aside")
          .classList.remove("hidden");
      }
    },
    headerLogoMouseLeave() {
      if (this.$root.checkElementHasClass("body", "aside-none")) {
        document
          .getElementById("project-name-no-aside")
          .classList.remove("hidden");
        document.getElementById("logo-text-no-aside").classList.add("hidden");
      }
    },
  },
  components: {
    Topbar,
    Menu,
    DelegationSidePanel,
  },
  setup() {
    return {
      headerWidthFluid,
      headerLeft,
      asideDisplay,
    };
  },
});
</script>
