<template>
  <div
    id="sx_aside"
    class="aside aside-hoverable"
    :class="[
      asideTheme === 'light' && 'aside-light',
      asideTheme === 'dark' && 'aside-dark',
    ]"
    data-sx-drawer="true"
    data-sx-drawer-name="aside"
    data-sx-drawer-activate="{default: true, lg: false}"
    data-sx-drawer-overlay="true"
    data-sx-drawer-width="{default:'200px', '300px': '250px'}"
    data-sx-drawer-direction="start"
    data-sx-drawer-toggle="#sx_aside_mobile_toggle"
  >
    <div class="aside-logo" id="sx_aside_logo">
      <div class="d-table">
        <div class="col-12 text-center">
          <a href="#">
            <img
              alt="SetXRM Cloud Solutions"
              :src="$config.media.logos.dark"
              class="img-fluid logo"
            />
            <div class="w-32 h-32 minimize-logo text-center fs-3">
              <i class="fas fa-house-user fs-3 text-white"></i>
            </div>
          </a>
        </div>
        <div class="col-12 text-center mt-2">
          <div class="project-name">
            {{ this.$store.getters._companyName }}
          </div>
        </div>
        <div
          id="sx_aside_toggle"
          class="aside-toggle btn px-0 btn-shadow btn-active-color-primary body-bg h-30px w-30px rotate"
          data-sx-toggle="true"
          data-sx-toggle-state="active"
          data-sx-toggle-target="body"
          data-sx-toggle-name="aside-minimize"
          @click="asideToggleClick"
        >
          <span
            class="svg-icon svg-icon-1 d-flex align-items-center justify-content-center h-100"
          >
            <i
              class="bi me-0 fs-4"
              :class="{
                'bi-lock-fill':
                  !asideMinimize || asideMinimize === asideMinimizeOffValue,
                'bi-unlock-fill': asideMinimize === asideMinimizeOnValue,
              }"
            ></i>
          </span>
        </div>
      </div>
    </div>
    <div class="aside-menu flex-column-fluid">
      <Menu></Menu>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import Menu from "@/layout/aside/Menu.vue";
import { asideTheme } from "@/core/helpers/config";
export default {
  name: "Aside",
  components: {
    Menu,
  },
  data() {
    return {
      asideMinimize: Object.readCookie("SxAsideMinimize"),
      asideMinimizeCookieName: "SxAsideMinimize",
      asideMinimizeOnValue: "on",
      asideMinimizeOffValue: "off",
    };
  },
  methods: {
    asideToggleClick() {
      var sxAsideMinimize = Object.readCookie(this.asideMinimizeCookieName),
        value = this.asideMinimizeOnValue;
      if (!String.isNullOrWhiteSpace(sxAsideMinimize)) {
        value =
          sxAsideMinimize == this.asideMinimizeOnValue
            ? this.asideMinimizeOffValue
            : this.asideMinimizeOnValue;
      }
      this.asideMinimize = value;
      Object.setCookie(this.asideMinimizeCookieName, value, 1);
    },
    asideLogoMouseOver() {
      if (
        !this.$root.checkElementAttribute("sx_body", "data-sx-aside-minimize")
      ) {
        document.getElementById("project-name").classList.add("hidden");
        document.getElementById("logo-text").classList.remove("hidden");
      }
    },
    asideLogoMouseLeave() {
      if (
        !this.$root.checkElementAttribute("sx_body", "data-sx-aside-minimize")
      ) {
        document.getElementById("project-name").classList.remove("hidden");
        document.getElementById("logo-text").classList.add("hidden");
      }
    },
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const { t } = useI18n();

    return {
      asideTheme,
      t,
    };
  },
};
</script>
