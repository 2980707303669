<template>
  <div
    id="sx_drawer_delegations"
    class="bg-white"
    data-sx-drawer="true"
    data-sx-drawer-name="delegations"
    data-sx-drawer-activate="true"
    data-sx-drawer-overlay="true"
    data-sx-drawer-width="{default:'300px', 'md': '500px'}"
    data-sx-drawer-direction="end"
    data-sx-drawer-toggle="#sx_drawer_delegations_toggle"
    data-sx-drawer-close="#sx_drawer_delegations_close"
  >
    <div class="card w-100 border-0" id="sx_drawer_delegations">
      <div
        class="card-header bg-primary p-2 border-0 rounded-0"
        id="sx_drawer_delegations_header"
      >
        <div class="card-title mt-0 mb-0 p-3">
          <i class="fas fa-users text-white float-start fs-4 me-2"></i>
          <div
            class="d-flex text-white justify-content-center flex-column me-3"
          >
            {{
              $t(
                "Components.Delegations.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
        </div>
        <div class="card-toolbar mt-0 mb-0 p-3">
          <i
            class="fas fa-times fs-4 text-white cursor-pointer"
            id="sx_drawer_delegations_close"
          ></i>
        </div>
      </div>
      <div class="card-body sx-drawer-body" id="sx_drawer_delegations_body">
        <div class="card rounded" v-if="isActiveDelegation">
          <div class="card-header bg-success p-3 border-0 rounded-0">
            <div class="card-title mt-0 mb-0">
              <i class="fa fa-user-secret text-white float-start fs-4 me-2"></i>
              <div
                class="d-flex text-white justify-content-center flex-column me-3"
              >
                {{
                  $t(
                    "Components.Delegations.MyActiveDelegation",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-8 align-middle">Delegation Informations</div>
              <div class="col-md-4 text-right">
                <button class="btn btn-danger w-100 float-end">
                  {{
                    $t(
                      "BaseModelFields.SignOut",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded" :class="{ 'mt-3': isActiveDelegation }">
          <div class="card-header bg-info p-3 border-0 rounded-0">
            <div class="card-title mt-0 mb-0">
              <i class="fas fa-users-cog text-white float-start fs-4 me-2"></i>
              <div
                class="d-flex text-white justify-content-center flex-column me-3"
              >
                {{
                  $t(
                    "Components.Delegations.NewTitle",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group">
                <div class="d-flex justify-content-between align-items-center">
                  <label
                    for="givingUser"
                    class="form-label fs-6 fw-bolder required"
                  >
                    {{
                      $t(
                        "Components.Delegations.GivingUser",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
                <Select2
                  :placeholder="
                    $t(
                      'BaseModelFields.Choose',
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  "
                  id="givingUser"
                  :settings="select2Settings"
                  class="select2-parent-container"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group">
                <div class="d-flex justify-content-between align-items-center">
                  <label
                    for="receivingUser"
                    class="form-label fs-6 fw-bolder required"
                  >
                    {{
                      $t(
                        "Components.Delegations.ReceivingUser",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
                <Select2
                  :placeholder="
                    $t(
                      'BaseModelFields.Choose',
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  "
                  id="receivingUser"
                  :settings="select2Settings"
                  class="select2-parent-container"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group">
                <div class="d-flex justify-content-between align-items-center">
                  <label
                    for="delegationStartDate"
                    class="form-label fs-6 fw-bolder required"
                  >
                    {{
                      $t(
                        "Components.Delegations.StartDate",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
                <DatePicker
                  type="date"
                  @valueChanged="onDelegationStartDateChange"
                  id="delegationStartDate"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="form-group">
                <div class="d-flex justify-content-between align-items-center">
                  <label
                    for="delegationEndDate"
                    class="form-label fs-6 fw-bolder required"
                  >
                    {{
                      $t(
                        "Components.Delegations.EndDate",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</label
                  >
                </div>
                <DatePicker
                  type="date"
                  :minDate="model.startDate"
                  id="delegationEndDate"
                />
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-4">
                <div class="form-group">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label
                      for="delegationIsActive"
                      class="form-label fs-6 fw-bolder"
                    >
                      {{
                        $t(
                          "Components.Delegations.IsActive",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label
                    >
                  </div>
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="delegationIsActive"
                      v-model="model.isActive"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-8 d-flex justify-content-end">
                <button
                  @click="saveDelegation"
                  type="button"
                  class="btn btn-success btn-save"
                >
                  <span>
                    <i class="fa fa-save"></i>
                    {{
                      $t(
                        "BaseModelFields.Save",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card rounded mt-3">
          <div class="card-header bg-info p-3 border-0 rounded-0">
            <div class="card-title mt-0 mb-0">
              <i class="fas fa-users text-white float-start fs-4 me-2"></i>
              <div
                class="d-flex text-white justify-content-center flex-column me-1"
              >
                {{
                  $t(
                    "Components.Delegations.LastDelegations",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div>
              <small class="text-white opacity-75 text-gray"
                >(0
                {{
                  $t(
                    "Components.Delegations.Delegation",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }})</small
              >
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer pt-4" id="sx_drawer_delegations_footer"></div>
    </div>
  </div>
</template>
<script>
import DatePicker from "@/components/devexpress/dateboxes/DatePickerByType.vue";
import Select2 from "vue3-select2-component";

export default {
  name: "DelegationSidePanel",
  components: {
    DatePicker,
    Select2,
  },
  data() {
    return {
      select2Settings: {
        allowClear: true,
        theme: "bootstrap-5",
        language: this.$root.getSelect2Locale(this.$store.state.activeLang),
      },
      isActiveDelegation: false,
      lastDelegations: [],
      model: {
        givingUser: null,
        receivingUser: null,
        startDate: null,
        endDate: null,
        isActive: true,
      },
    };
  },
  methods: {
    onDelegationStartDateChange(val) {
      this.model.startDate = val;
    },
    saveDelegation() {
      //
    },
  },
};
</script>
