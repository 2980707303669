<template>
  <MenuComponent menu-selector="#sx-search-menu">
    <template v-slot:toggle>
      <div
        id="sx_header_search"
        class="d-flex align-items-stretch"
        :class="{ 'h-100': $isMobile() }"
        data-sx-menu-target="#sx-search-menu"
        :data-sx-menu-trigger="$isMobile() ? 'click' : 'hover'"
        data-sx-menu-attach="parent"
        data-sx-menu-placement="bottom-end"
        data-sx-menu-flip="bottom"
      >
        <div class="d-flex align-items-center" id="sx_header_search_toggle">
          <div class="d-flex align-items-center">
            <i class="fa fa-search text-black fa-2x"></i>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div
        class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
        data-sx-menu="true"
        id="sx-search-menu"
      >
        <div class="d-flex flex-column bgi-no-repeat rounded-top bg-primary">
          <h3 class="text-white fw-bold px-9 mt-10 mb-6">
            {{
              $t(
                "Components.Topbar.SearchEngine",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
            <label class="fs-8 opacity-75 ps-3" for="app-search">
              {{
                $t(
                  "BaseModelFields.WriteSomething",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label
            >
          </h3>
        </div>
        <input
          ref="inputRef"
          v-model="search"
          :spellcheck="$isTextSpellCheck"
          :autocomplete="$isAutoComplete"
          type="text"
          class="form-control"
          name="search"
          id="app-search"
          :placeholder="
            $t(
              'BaseModelFields.Search',
              {},
              { locale: this.$store.state.activeLang }
            )
          "
        />
      </div>
    </template>
  </MenuComponent>
</template>

<script>
// import Results from "@/layout/header/partials/search/Results.vue";
// import Main from "@/layout/header/partials/search/Main.vue";
// import Empty from "@/layout/header/partials/search/Empty.vue";
import MenuComponent from "@/components/custom/menu/MenuComponent.vue";

export default {
  name: "SearchEngine",
  data() {
    return {
      search: "",
    };
  },
  components: {
    MenuComponent,
  },
};
</script>
