<template>
  <div
    id="sx_aside_menu_wrapper"
    ref="scrollElRef"
    class="hover-scroll-overlay-y"
    data-sx-scroll="true"
    data-sx-scroll-activate="{default: false, lg: true}"
    data-sx-scroll-dependencies="#sx_aside_logo, #sx_aside_footer"
    data-sx-scroll-height="auto"
    data-sx-scroll-offset="0"
    data-sx-scroll-wrappers="#sx_aside_menu"
  >
    <div
      id="#sx_header_menu"
      class="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
      data-sx-menu="true"
    >
      <div class="menu-item">
        <div
          class="menu-content"
          :class="{
            'pt-2': $isMobile(),
          }"
        >
          <span class="menu-section text-muted text-uppercase fs-8 ls-1">
            {{
              $t(
                "BaseModelFields.Pages",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</span
          >
        </div>
      </div>
      <template v-for="(menuItem, j) in sideBarMenuItems" :key="j">
        <div
          class="menu-item"
          v-if="!menuItem.isCustomObject && menuItem.menuChildItems.length == 0"
        >
          <a
            class="menu-link first-menu-link"
            :href="String.isNullOrWhiteSpace(menuItem.url) ? '#' : menuItem.url"
            :class="{ active: hasActiveChildren(menuItem.url) }"
            :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
          >
            <span v-if="menuItem.icon" class="menu-icon">
              <i
                :class="menuItem.icon"
                class="fs-4"
                :style="{ color: menuItem.iconColor }"
                v-if="menuItem.iconColor"
              ></i>
              <i :class="menuItem.icon" class="fs-4" v-else></i>
            </span>
            <span class="menu-title">{{ translate(menuItem.name) }}</span>
          </a>
        </div>
        <div
          v-else-if="
            !menuItem.isCustomObject && menuItem.menuChildItems.length > 0
          "
          class="menu-item menu-accordion"
          data-sx-menu-sub="accordion"
          data-sx-menu-trigger="click"
        >
          <span
            class="menu-link first-menu-link"
            :class="{
              active: hasActiveChildren(menuItem.url, menuItem.menuChildItems),
            }"
          >
            <span v-if="menuItem.icon" class="menu-icon">
              <i
                :class="menuItem.icon"
                class="fs-4"
                :style="{ color: menuItem.iconColor }"
                v-if="menuItem.iconColor"
              ></i>
              <i :class="menuItem.icon" class="fs-4" v-else></i>
            </span>
            <span class="menu-title">{{ translate(menuItem.name) }}</span>
            <span class="menu-arrow"></span>
          </span>
          <div class="menu-sub menu-sub-accordion">
            <template v-for="(item2, k) in menuItem.menuChildItems" :key="k">
              <div
                class="menu-item menu-accordion"
                data-sx-menu-sub="accordion"
                data-sx-menu-trigger="click"
                v-if="item2.menuChildItems.length > 0 || item2.isCustomObject"
              >
                <span class="menu-link">
                  <span v-if="item2.icon" class="menu-icon">
                    <i
                      :class="item2.icon"
                      class="fs-4"
                      :style="{ color: item2.iconColor }"
                      v-if="item2.iconColor"
                    ></i>
                    <i :class="item2.icon" class="fs-4" v-else></i>
                  </span>
                  <span class="menu-title">{{ translate(item2.name) }}</span>
                  <span class="menu-arrow"></span>
                </span>
                <div class="menu-sub menu-sub-accordion">
                  <template v-if="item2.isCustomObject">
                    <router-link
                      class="menu-link p-item-3"
                      :to="{ name: 'new', params: { key: item2.key } }"
                      :target="item2.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">
                        {{
                          $t(
                            "Set.New",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </span>
                    </router-link>
                    <router-link
                      class="menu-link p-item-3"
                      :to="{ name: 'list', params: { key: item2.key } }"
                      :target="item2.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">{{
                        $t(
                          "Set.List",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</span>
                    </router-link>
                  </template>
                  <template v-for="(item3, k) in item2.menuChildItems" :key="k">
                    <div
                      class="menu-item menu-accordion"
                      data-sx-menu-sub="accordion"
                      data-sx-menu-trigger="click"
                      v-if="
                        item3.menuChildItems.length > 0 || item3.isCustomObject
                      "
                    >
                      <span class="menu-link p-item-3">
                        <span v-if="item3.icon" class="menu-icon">
                          <i
                            :class="item3.icon"
                            class="fs-4"
                            :style="{ color: item3.iconColor }"
                            v-if="item3.iconColor"
                          ></i>
                          <i :class="item3.icon" class="fs-4" v-else></i>
                        </span>
                        <span class="menu-title">{{
                          translate(item3.name)
                        }}</span>
                        <span class="menu-arrow"></span>
                      </span>
                      <div class="menu-sub menu-sub-accordion">
                        <template v-if="item3.isCustomObject">
                          <router-link
                            class="menu-link p-item-4"
                            :to="{ name: 'new', params: { key: item3.key } }"
                            :target="item3.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span class="menu-title">
                              {{
                                $t(
                                  "Set.New",
                                  {},
                                  { locale: this.$store.state.activeLang }
                                )
                              }}
                            </span>
                          </router-link>
                          <router-link
                            class="menu-link p-item-4"
                            :to="{ name: 'list', params: { key: item3.key } }"
                            :target="item3.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span class="menu-title">{{
                              $t(
                                "Set.List",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}</span>
                          </router-link>
                        </template>
                        <template
                          v-for="(item4, k) in item3.menuChildItems"
                          :key="k"
                        >
                          <div
                            class="menu-item menu-accordion"
                            data-sx-menu-sub="accordion"
                            data-sx-menu-trigger="click"
                            v-if="
                              item4.menuChildItems.length > 0 ||
                              item4.isCustomObject
                            "
                          >
                            <span class="menu-link p-item-4">
                              <span v-if="item4.icon" class="menu-icon">
                                <i
                                  :class="item4.icon"
                                  class="fs-4"
                                  :style="{ color: item4.iconColor }"
                                  v-if="item4.iconColor"
                                ></i>
                                <i :class="item4.icon" class="fs-4" v-else></i>
                              </span>
                              <span class="menu-title">{{
                                translate(item4.name)
                              }}</span>
                              <span class="menu-arrow"></span>
                            </span>
                            <div class="menu-sub menu-sub-accordion">
                              <template v-if="item4.isCustomObject">
                                <router-link
                                  class="menu-link p-item-5"
                                  :to="{
                                    name: 'new',
                                    params: { key: item4.key },
                                  }"
                                  :target="
                                    item4.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span class="menu-title">
                                    {{
                                      $t(
                                        "Set.New",
                                        {},
                                        { locale: this.$store.state.activeLang }
                                      )
                                    }}
                                  </span>
                                </router-link>
                                <router-link
                                  class="menu-link p-item-5"
                                  :to="{
                                    name: 'list',
                                    params: { key: item4.key },
                                  }"
                                  :target="
                                    item4.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span class="menu-title">{{
                                    $t(
                                      "Set.List",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}</span>
                                </router-link>
                              </template>
                              <template
                                v-for="(item5, k) in item4.menuChildItems"
                                :key="k"
                              >
                                <a
                                  class="menu-link p-item-5"
                                  :href="
                                    String.isNullOrWhiteSpace(item5.url)
                                      ? '#'
                                      : item5.url
                                  "
                                  :target="
                                    item5.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span v-if="item5.icon" class="menu-icon">
                                    <i
                                      :class="item5.icon"
                                      class="fs-4"
                                      :style="{ color: item5.iconColor }"
                                      v-if="item5.iconColor"
                                    ></i>
                                    <i
                                      :class="item5.icon"
                                      class="fs-4"
                                      v-else
                                    ></i>
                                  </span>
                                  <span class="menu-title">{{
                                    translate(item5.name)
                                  }}</span>
                                </a>
                              </template>
                            </div>
                          </div>
                          <a
                            class="menu-link p-item-4"
                            :href="
                              String.isNullOrWhiteSpace(item4.url)
                                ? '#'
                                : item4.url
                            "
                            :target="item4.isOpenNewTab ? '_blank' : '_self'"
                            v-else
                          >
                            <span v-if="item4.icon" class="menu-icon">
                              <i
                                :class="item4.icon"
                                class="fs-4"
                                :style="{ color: item4.iconColor }"
                                v-if="item4.iconColor"
                              ></i>
                              <i :class="item4.icon" class="fs-4" v-else></i>
                            </span>
                            <span class="menu-title">{{
                              translate(item4.name)
                            }}</span>
                          </a>
                        </template>
                      </div>
                    </div>
                    <a
                      class="menu-link p-item-3"
                      :href="
                        String.isNullOrWhiteSpace(item3.url) ? '#' : item3.url
                      "
                      :target="item3.isOpenNewTab ? '_blank' : '_self'"
                      v-else
                    >
                      <span v-if="item3.icon" class="menu-icon">
                        <i
                          :class="item3.icon"
                          class="fs-4"
                          :style="{ color: item3.iconColor }"
                          v-if="item3.iconColor"
                        ></i>
                        <i :class="item3.icon" class="fs-4" v-else></i>
                      </span>
                      <span class="menu-title">{{
                        translate(item3.name)
                      }}</span>
                    </a>
                  </template>
                </div>
              </div>
              <a
                class="menu-link"
                :href="String.isNullOrWhiteSpace(item2.url) ? '#' : item2.url"
                :target="item2.isOpenNewTab ? '_blank' : '_self'"
                v-else
              >
                <span v-if="item2.icon" class="menu-icon">
                  <i
                    :class="item2.icon"
                    class="fs-4"
                    :style="{ color: item2.iconColor }"
                    v-if="item2.iconColor"
                  ></i>
                  <i :class="item2.icon" class="fs-4" v-else></i>
                </span>
                <span class="menu-title">{{ translate(item2.name) }}</span>
              </a>
            </template>
          </div>
        </div>
        <div
          v-else-if="menuItem.isCustomObject"
          class="menu-item menu-accordion"
          data-sx-menu-sub="accordion"
          data-sx-menu-trigger="click"
        >
          <span
            class="menu-link first-menu-link"
            :class="{
              active: hasActiveChildren(menuItem.key, menuItem.menuChildItems),
            }"
          >
            <span v-if="menuItem.icon" class="menu-icon">
              <i
                :class="menuItem.icon"
                class="fs-4"
                :style="{ color: menuItem.iconColor }"
                v-if="menuItem.iconColor"
              ></i>
              <i :class="menuItem.icon" class="fs-4" v-else></i>
            </span>
            <span class="menu-title">{{ translate(menuItem.name) }}</span>
            <span class="menu-arrow"></span>
          </span>
          <div class="menu-sub menu-sub-accordion">
            <router-link
              class="menu-link"
              :to="{ name: 'new', params: { key: menuItem.key } }"
              :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
            >
              <span class="menu-title">
                {{
                  $t("Set.New", {}, { locale: this.$store.state.activeLang })
                }}
              </span>
            </router-link>
            <router-link
              class="menu-link"
              :to="{ name: 'list', params: { key: menuItem.key } }"
              :target="menuItem.isOpenNewTab ? '_blank' : '_self'"
            >
              <span class="menu-title">{{
                $t("Set.List", {}, { locale: this.$store.state.activeLang })
              }}</span>
            </router-link>
            <template v-for="(item2, k) in menuItem.menuChildItems" :key="k">
              <div
                class="menu-item menu-accordion"
                data-sx-menu-sub="accordion"
                data-sx-menu-trigger="click"
                v-if="item2.menuChildItems.length > 0 || item2.isCustomObject"
              >
                <span class="menu-link">
                  <span v-if="item2.icon" class="menu-icon">
                    <i
                      :class="item2.icon"
                      class="fs-4"
                      :style="{ color: item2.iconColor }"
                      v-if="item2.iconColor"
                    ></i>
                    <i :class="item2.icon" class="fs-4" v-else></i>
                  </span>
                  <span class="menu-title">{{ translate(item2.name) }}</span>
                  <span class="menu-arrow"></span>
                </span>
                <div class="menu-sub menu-sub-accordion">
                  <template v-if="item2.isCustomObject">
                    <router-link
                      class="menu-link p-item-3"
                      :to="{ name: 'new', params: { key: item2.key } }"
                      :target="item2.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">
                        {{
                          $t(
                            "Set.New",
                            {},
                            { locale: this.$store.state.activeLang }
                          )
                        }}
                      </span>
                    </router-link>
                    <router-link
                      class="menu-link p-item-3"
                      :to="{ name: 'list', params: { key: item2.key } }"
                      :target="item2.isOpenNewTab ? '_blank' : '_self'"
                    >
                      <span class="menu-title">{{
                        $t(
                          "Set.List",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</span>
                    </router-link>
                  </template>
                  <template v-for="(item3, k) in item2.menuChildItems" :key="k">
                    <div
                      class="menu-item menu-accordion"
                      data-sx-menu-sub="accordion"
                      data-sx-menu-trigger="click"
                      v-if="
                        item3.menuChildItems.length > 0 || item3.isCustomObject
                      "
                    >
                      <span class="menu-link p-item-3">
                        <span v-if="item3.icon" class="menu-icon">
                          <i
                            :class="item3.icon"
                            class="fs-4"
                            :style="{ color: item3.iconColor }"
                            v-if="item3.iconColor"
                          ></i>
                          <i :class="item3.icon" class="fs-4" v-else></i>
                        </span>
                        <span class="menu-title">{{
                          translate(item3.name)
                        }}</span>
                        <span class="menu-arrow"></span>
                      </span>
                      <div class="menu-sub menu-sub-accordion">
                        <template v-if="item3.isCustomObject">
                          <router-link
                            class="menu-link p-item-4"
                            :to="{ name: 'new', params: { key: item3.key } }"
                            :target="item3.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span class="menu-title">
                              {{
                                $t(
                                  "Set.New",
                                  {},
                                  { locale: this.$store.state.activeLang }
                                )
                              }}
                            </span>
                          </router-link>
                          <router-link
                            class="menu-link p-item-4"
                            :to="{ name: 'list', params: { key: item3.key } }"
                            :target="item3.isOpenNewTab ? '_blank' : '_self'"
                          >
                            <span class="menu-title">{{
                              $t(
                                "Set.List",
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}</span>
                          </router-link>
                        </template>
                        <template
                          v-for="(item4, k) in item3.menuChildItems"
                          :key="k"
                        >
                          <div
                            class="menu-item menu-accordion"
                            data-sx-menu-sub="accordion"
                            data-sx-menu-trigger="click"
                            v-if="
                              item4.menuChildItems.length > 0 ||
                              item4.isCustomObject
                            "
                          >
                            <span class="menu-link p-item-4">
                              <span v-if="item4.icon" class="menu-icon">
                                <i
                                  :class="item4.icon"
                                  class="fs-4"
                                  :style="{ color: item4.iconColor }"
                                  v-if="item4.iconColor"
                                ></i>
                                <i :class="item4.icon" class="fs-4" v-else></i>
                              </span>
                              <span class="menu-title">{{
                                translate(item4.name)
                              }}</span>
                              <span class="menu-arrow"></span>
                            </span>
                            <div class="menu-sub menu-sub-accordion">
                              <template v-if="item4.isCustomObject">
                                <router-link
                                  class="menu-link p-item-5"
                                  :to="{
                                    name: 'new',
                                    params: { key: item4.key },
                                  }"
                                  :target="
                                    item4.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span class="menu-title">
                                    {{
                                      $t(
                                        "Set.New",
                                        {},
                                        { locale: this.$store.state.activeLang }
                                      )
                                    }}
                                  </span>
                                </router-link>
                                <router-link
                                  class="menu-link p-item-5"
                                  :to="{
                                    name: 'list',
                                    params: { key: item4.key },
                                  }"
                                  :target="
                                    item4.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span class="menu-title">{{
                                    $t(
                                      "Set.List",
                                      {},
                                      { locale: this.$store.state.activeLang }
                                    )
                                  }}</span>
                                </router-link>
                              </template>
                              <template
                                v-for="(item5, k) in item4.menuChildItems"
                                :key="k"
                              >
                                <a
                                  class="menu-link p-item-5"
                                  :href="
                                    String.isNullOrWhiteSpace(item5.url)
                                      ? '#'
                                      : item5.url
                                  "
                                  :target="
                                    item5.isOpenNewTab ? '_blank' : '_self'
                                  "
                                >
                                  <span v-if="item5.icon" class="menu-icon">
                                    <i
                                      :class="item5.icon"
                                      class="fs-4"
                                      :style="{ color: item5.iconColor }"
                                      v-if="item5.iconColor"
                                    ></i>
                                    <i
                                      :class="item5.icon"
                                      class="fs-4"
                                      v-else
                                    ></i>
                                  </span>
                                  <span class="menu-title">{{
                                    translate(item5.name)
                                  }}</span>
                                </a>
                              </template>
                            </div>
                          </div>
                          <a
                            class="menu-link p-item-4"
                            :href="
                              String.isNullOrWhiteSpace(item4.url)
                                ? '#'
                                : item4.url
                            "
                            :target="item4.isOpenNewTab ? '_blank' : '_self'"
                            v-else
                          >
                            <span v-if="item4.icon" class="menu-icon">
                              <i
                                :class="item4.icon"
                                class="fs-4"
                                :style="{ color: item4.iconColor }"
                                v-if="item4.iconColor"
                              ></i>
                              <i :class="item4.icon" class="fs-4" v-else></i>
                            </span>
                            <span class="menu-title">{{
                              translate(item4.name)
                            }}</span>
                          </a>
                        </template>
                      </div>
                    </div>
                    <a
                      class="menu-link p-item-3"
                      :href="
                        String.isNullOrWhiteSpace(item3.url) ? '#' : item3.url
                      "
                      :target="item3.isOpenNewTab ? '_blank' : '_self'"
                      v-else
                    >
                      <span v-if="item3.icon" class="menu-icon">
                        <i
                          :class="item3.icon"
                          class="fs-4"
                          :style="{ color: item3.iconColor }"
                          v-if="item3.iconColor"
                        ></i>
                        <i :class="item3.icon" class="fs-4" v-else></i>
                      </span>
                      <span class="menu-title">{{
                        translate(item3.name)
                      }}</span>
                    </a>
                  </template>
                </div>
              </div>
              <a
                class="menu-link"
                :href="String.isNullOrWhiteSpace(item2.url) ? '#' : item2.url"
                :target="item2.isOpenNewTab ? '_blank' : '_self'"
                v-else
              >
                <span v-if="item2.icon" class="menu-icon">
                  <i
                    :class="item2.icon"
                    class="fs-4"
                    :style="{ color: item2.iconColor }"
                    v-if="item2.iconColor"
                  ></i>
                  <i :class="item2.icon" class="fs-4" v-else></i>
                </span>
                <span class="menu-title">{{ translate(item2.name) }}</span>
              </a>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";
import { asideMenuIcons } from "@/core/helpers/config";
import AsideMenuConfig from "@/core/config/AsideMenuConfig";
import { mapState } from "vuex";

export default {
  name: "Menu",
  components: {},
  data() {
    return {
      sideBarMenuItems: [],
    };
  },
  methods: {
    signOut() {
      this.$root.signOut();
    },
  },
  watch: {
    "$store.state.userData.informations": {
      immediate: true,
      handler() {
        this.sideBarMenuItems = !String.isNullOrWhiteSpace(
          this.$store.getters.userInfo
        )
          ? this.$store.getters.userInfo.activeMenuItems
              .filter((f) => f.isSideBarItem)
              .sort(function (a, b) {
                return a.displayOrder - b.displayOrder;
              })
          : [];
      },
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = (ref < null) | (HTMLElement > null);

    onMounted(() => {
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      // if (te(text)) {
      //   return t(text);
      // } else {
      return text;
      // }
    };

    const translateForSystem = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (itemRoute, parentItems) => {
      var currentRoute = route.path;
      if (route.meta.isSetPage) {
        var splitCurrentRoute = currentRoute.split("/");
        currentRoute = splitCurrentRoute[3]; //co key
      }

      if (String.isNullOrWhiteSpace(itemRoute)) {
        if (!String.isNullOrWhiteSpace(parentItems)) {
          var keys = parentItems.map(function (m) {
            if (
              !String.isNullOrWhiteSpace(m) &&
              !String.isNullOrWhiteSpace(m.url)
            )
              return m.url.split("/").pop();
          });

          return keys.includes(currentRoute);
        }
      }

      return currentRoute === itemRoute;
    };

    return {
      hasActiveChildren,
      AsideMenuConfig,
      asideMenuIcons,
      translate,
      translateForSystem,
    };
  },
};
</script>
