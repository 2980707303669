const DocMenuConfig = [
  {
    pages: [
      // {
      //   heading: "Dashboard",
      //   route: "/Dashboard",
      //   svgIcon: "",
      //   fontIcon: "bi-app-indicator",
      //   isFontIcon: true,
      // },
      // {
      //   heading: "BaseModelFields.AccountSettings",
      //   route: "/Account/Settings",
      //   svgIcon: "",
      //   fontIcon: "bi-gear",
      //   isFontIcon: true,
      // },
    ],
  },
];

export default DocMenuConfig;
