const DocMenuConfig = [
  {
    pages: [
      // {
      //   heading: "Dashboard",
      //   route: "/Dashboard",
      //   svgIcon: "/extras/media/icons/duotune/art/art002.svg",
      //   fontIcon: "fa fa-home fs-4 text-primary",
      // },
    ],
  },
];

export default DocMenuConfig;
