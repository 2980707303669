<template>
  <template v-if="isDrawerSidePanel">
    <div class="d-flex align-items-center" id="sx_drawer_delegations_toggle">
      <div class="d-flex align-items-center cursor-pointer">
        <i
          class="fa fa-users text-black fa-2x"
          :data-bs-toggle="!$isMobile() ? 'tooltip' : ''"
          data-bs-placement="bottom"
          :title="
            $t(
              'Components.Delegations.Title',
              {},
              { locale: this.$store.state.activeLang }
            )
          "
        ></i>
      </div>
    </div>
  </template>
  <MenuComponent menu-selector="#sx-delegation-menu" v-else>
    <template v-slot:toggle>
      <div
        id="sx_header_delegations"
        class="d-flex align-items-stretch"
        :class="{ 'h-100': $isMobile() }"
        data-sx-menu-target="#sx-delegation-menu"
        data-sx-menu-trigger="click"
        data-sx-menu-attach="parent"
        data-sx-menu-placement="bottom-end"
        data-sx-menu-flip="bottom"
      >
        <div class="d-flex align-items-center" id="sx_header_delegation_toggle">
          <div class="d-flex align-items-center cursor-pointer">
            <i class="fa fa-users text-black fa-2x"></i>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <div
        class="menu menu-sub menu-sub-dropdown menu-column w-500px w-lg-500px"
        data-sx-menu="true"
        id="sx-delegation-menu"
      >
        <div class="d-flex flex-column bgi-no-repeat rounded-top">
          <h3 class="text-black fw-bold px-9 mt-10 mb-6">
            {{
              $t(
                "Components.Delegations.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h3>
        </div>
        <div class="card ms-2 me-2 mt-2 mb-2">
          <div class="alert alert-warning mb-0">
            {{
              $t(
                "BaseModelFields.NoRecordFound",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <!-- <table class="table table-primary fs-5">
            <thead>
              <tr>
                <th>User</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="2">
                 
                </td>
              </tr>
            </tbody>
          </table> -->
        </div>
      </div>
    </template>
  </MenuComponent>
</template>

<script>
import MenuComponent from "@/components/custom/menu/MenuComponent.vue";
export default {
  name: "Delegations",
  props: ["isDrawerSidePanel"],
  components: {
    MenuComponent,
  },
};
</script>
